import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"1000px"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,{attrs:{"elevation":"2"}},[_c(VForm,{ref:"formLocation",model:{value:(_vm.formNewRallyValidation),callback:function ($$v) {_vm.formNewRallyValidation=$$v},expression:"formNewRallyValidation"}},[_c(VToolbar,{attrs:{"flat":"","color":"blue","dark":""}},[_c(VToolbarTitle,[_vm._v("Add new rally leg")])],1),_c(VCardText,[_c(VRow,{staticClass:"mx-2",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VRadioGroup,{attrs:{"row":"","dense":"","mandatory":"","prepend-icon":"mdi-counter"},model:{value:(_vm.newRally.legid),callback:function ($$v) {_vm.$set(_vm.newRally, "legid", $$v)},expression:"newRally.legid"}},[_c(VRadio,{staticClass:"mr-10",attrs:{"label":"Leg 1","value":"1"}}),_c(VRadio,{staticClass:"mr-10",attrs:{"label":"Leg 2","value":"2"}}),_c(VRadio,{staticClass:"mr-10",attrs:{"label":"Leg 3","value":"3"}}),_c(VRadio,{staticClass:"mr-10",attrs:{"label":"Leg 4","value":"4"}}),_c(VRadio,{staticClass:"mr-10",attrs:{"label":"Leg 5","value":"5"}})],1)],1)],1),_c(VRow,{staticClass:"mx-2",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Enter the name of the rally","prepend-icon":"mdi-highway","dense":"","rules":[
                _vm.validateRequired(_vm.newRally.rallyname),
                _vm.validateHTML(_vm.newRally.rallyname),
                _vm.validateLength(_vm.newRally.rallyname, 250),
                _vm.validateCharacters(_vm.newRally.rallyname)
              ]},model:{value:(_vm.newRally.rallyname),callback:function ($$v) {_vm.$set(_vm.newRally, "rallyname", $$v)},expression:"newRally.rallyname"}})],1)],1),_c(VRow,{staticClass:"mx-2",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"prepend-icon":"mdi-form-textbox","dense":"","label":"Description","type":"text","rules":[
                _vm.validateHTML(_vm.newRally.description),
                _vm.validateLength(_vm.newRally.description, 250),
                _vm.validateCharacters(_vm.newRally.description)
              ]},model:{value:(_vm.newRally.description),callback:function ($$v) {_vm.$set(_vm.newRally, "description", $$v)},expression:"newRally.description"}})],1)],1),_c(VRow,{staticClass:"mx-2",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"prepend-icon":"mdi-file-link-outline","dense":"","clearable":"","label":"Rally website URL","type":"text","rules":[
                _vm.validateURLprotocol(_vm.newRally.rallyurl),
                _vm.validateIsURL(_vm.newRally.rallyurl),
                _vm.validateHTML(_vm.newRally.rallyurl),
                _vm.validateLength(_vm.newRally.rallyurl, 150),
                _vm.validateCharacters(_vm.newRally.rallyurl)
              ]},model:{value:(_vm.newRally.rallyurl),callback:function ($$v) {_vm.$set(_vm.newRally, "rallyurl", $$v)},expression:"newRally.rallyurl"}})],1)],1),_c(VRow,{staticClass:"mx-2",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"2"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":32,"transition":"scale-transition","offset-y":"","min-width":"100px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"label":"Start date","prepend-icon":"mdi-clock-start","readonly":"","dense":""},model:{value:(_vm.newRally.start.date),callback:function ($$v) {_vm.$set(_vm.newRally.start, "date", $$v)},expression:"newRally.start.date"}},on))]}}]),model:{value:(_vm.menuStartDate),callback:function ($$v) {_vm.menuStartDate=$$v},expression:"menuStartDate"}},[_c(VDatePicker,{attrs:{"show-current":""},on:{"input":function($event){_vm.menuStartDate = false}},model:{value:(_vm.newRally.start.date),callback:function ($$v) {_vm.$set(_vm.newRally.start, "date", $$v)},expression:"newRally.start.date"}})],1)],1),_c(VCol,{attrs:{"cols":"1"}},[_c(VMenu,{ref:"menuStartTime",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.newRally.start.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.newRally.start, "time", $event)},"update:return-value":function($event){return _vm.$set(_vm.newRally.start, "time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"label":"Start time","rules":[_vm.validateRequired(_vm.newRally.start.time)],"dense":""},model:{value:(_vm.newRally.start.time),callback:function ($$v) {_vm.$set(_vm.newRally.start, "time", $$v)},expression:"newRally.start.time"}},on))]}}]),model:{value:(_vm.menuStartTime),callback:function ($$v) {_vm.menuStartTime=$$v},expression:"menuStartTime"}},[(_vm.menuStartTime)?_c(VTimePicker,{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menuStartTime.save(_vm.newRally.start.time)}},model:{value:(_vm.newRally.start.time),callback:function ($$v) {_vm.$set(_vm.newRally.start, "time", $$v)},expression:"newRally.start.time"}}):_vm._e()],1)],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VTextField,{attrs:{"dense":"","readonly":"","label":"Start timezone"},model:{value:(_vm.startBonus.timezoneShort),callback:function ($$v) {_vm.$set(_vm.startBonus, "timezoneShort", $$v)},expression:"startBonus.timezoneShort"}})],1),_c(VSpacer),_c(VCol,{staticClass:"ml-5",attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"label":"Start latitude","type":"number","dense":"","prepend-icon":"mdi-map-marker","rules":[
                _vm.validateRequired(_vm.newRally.start.latitude),
                _vm.validateMinimum(_vm.newRally.start.latitude, -90),
                _vm.validateMaximum(_vm.newRally.start.latitude, 90)
              ]},model:{value:(_vm.newRally.start.latitude),callback:function ($$v) {_vm.$set(_vm.newRally.start, "latitude", $$v)},expression:"newRally.start.latitude"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"label":"Start longitude","type":"number","dense":"","append-icon":"mdi-map-search","rules":[
                _vm.validateRequired(_vm.newRally.start.longitude),
                _vm.validateMinimum(_vm.newRally.start.longitude, -180),
                _vm.validateMaximum(_vm.newRally.start.longitude, 180)
              ]},on:{"click:append":function($event){_vm.rallyLocation = 'start'
                _vm.showAddressSearchDialog = true}},model:{value:(_vm.newRally.start.longitude),callback:function ($$v) {_vm.$set(_vm.newRally.start, "longitude", $$v)},expression:"newRally.start.longitude"}})],1)],1),_c(VRow,{staticClass:"mx-2",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"2"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":32,"transition":"scale-transition","offset-y":"","min-width":"100px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"label":"End date","prepend-icon":"mdi-clock-end","readonly":"","dense":""},model:{value:(_vm.newRally.end.date),callback:function ($$v) {_vm.$set(_vm.newRally.end, "date", $$v)},expression:"newRally.end.date"}},on))]}}]),model:{value:(_vm.menuEndDate),callback:function ($$v) {_vm.menuEndDate=$$v},expression:"menuEndDate"}},[_c(VDatePicker,{attrs:{"show-current":"","min":_vm.newRally.start.date},on:{"input":function($event){_vm.menuEndDate = false}},model:{value:(_vm.newRally.end.date),callback:function ($$v) {_vm.$set(_vm.newRally.end, "date", $$v)},expression:"newRally.end.date"}})],1)],1),_c(VCol,{attrs:{"cols":"1"}},[_c(VMenu,{ref:"menuEndTime",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.newRally.end.time,"transition":"scale-transition","rules":[_vm.validateRequired(_vm.newBonus.points)],"offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.newRally.end, "time", $event)},"update:return-value":function($event){return _vm.$set(_vm.newRally.end, "time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"label":"End time","rules":[_vm.validateRequired(_vm.newRally.end.time)],"readonly":"","dense":""},model:{value:(_vm.newRally.end.time),callback:function ($$v) {_vm.$set(_vm.newRally.end, "time", $$v)},expression:"newRally.end.time"}},on))]}}]),model:{value:(_vm.menuEndTime),callback:function ($$v) {_vm.menuEndTime=$$v},expression:"menuEndTime"}},[(_vm.menuEndTime)?_c(VTimePicker,{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menuEndTime.save(_vm.newRally.end.time)}},model:{value:(_vm.newRally.end.time),callback:function ($$v) {_vm.$set(_vm.newRally.end, "time", $$v)},expression:"newRally.end.time"}}):_vm._e()],1)],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VTextField,{attrs:{"dense":"","readonly":"","label":"End timezone"},model:{value:(_vm.endBonus.timezoneShort),callback:function ($$v) {_vm.$set(_vm.endBonus, "timezoneShort", $$v)},expression:"endBonus.timezoneShort"}})],1),_c(VSpacer),_c(VCol,{staticClass:"ml-5",attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"label":"End latitude","type":"number","dense":"","prepend-icon":"mdi-map-marker","rules":[
                _vm.validateRequired(_vm.newRally.end.latitude),
                _vm.validateMinimum(_vm.newRally.end.latitude, -90),
                _vm.validateMaximum(_vm.newRally.end.latitude, 90)
              ]},model:{value:(_vm.newRally.end.latitude),callback:function ($$v) {_vm.$set(_vm.newRally.end, "latitude", $$v)},expression:"newRally.end.latitude"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"label":"End longitude","type":"number","append-icon":"mdi-map-search","dense":"","rules":[
                _vm.validateRequired(_vm.newRally.end.longitude),
                _vm.validateMinimum(_vm.newRally.end.longitude, -180),
                _vm.validateMaximum(_vm.newRally.end.longitude, 180)
              ]},on:{"click:append":function($event){_vm.rallyLocation = 'end'
                _vm.showAddressSearchDialog = true}},model:{value:(_vm.newRally.end.longitude),callback:function ($$v) {_vm.$set(_vm.newRally.end, "longitude", $$v)},expression:"newRally.end.longitude"}})],1)],1),_c(VRow,{staticClass:"mx-2",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VSlider,{attrs:{"min":"0","max":"240","step":"15","thumb-label":"","thumb-size":"25","dense":"","label":"Penalty window","prepend-icon":"mdi-clock-alert-outline"},model:{value:(_vm.newRally.penaltywindow),callback:function ($$v) {_vm.$set(_vm.newRally, "penaltywindow", $$v)},expression:"newRally.penaltywindow"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c('div',{staticClass:"mt-1 grey--text"},[_vm._v(" "+_vm._s(_vm.newRally.penaltywindow)+" minutes ")])]),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"dense":"","type":"text","label":"Penalty points per minute","prepend-icon":"mdi-elevation-decline","rules":[
                _vm.validateRequired(_vm.newRally.penaltypoints),
                _vm.validateMinimum(_vm.newRally.penaltypoints, 0),
                _vm.validateMaximum(_vm.newRally.penaltypoints, 10000)
              ]},model:{value:(_vm.newRally.penaltypoints),callback:function ($$v) {_vm.$set(_vm.newRally, "penaltypoints", $$v)},expression:"newRally.penaltypoints"}})],1)],1)],1),(_vm.formNewRallyValidation)?_c(VToolbar,{staticClass:"mt-6",attrs:{"dense":"","flat":"","color":"blue lighten-3","dark":""}},[_c(VToolbarTitle,[_vm._v("Non-mappable bonuses")]),_c(VSpacer),(
            _vm.newRally.end.longitude &&
            _vm.newRally.end.latitude &&
            _vm.newRally.start.longitude &&
            _vm.newRally.start.latitude
          )?_c(VBtn,{staticClass:"ml-2",attrs:{"dark":"","small":"","color":"blue"}},[_c(VIcon,{on:{"click":function($event){return _vm.addBonus('rest')}}},[_vm._v("mdi-sleep")])],1):_vm._e(),(_vm.formNewRallyValidation)?_c(VBtn,{staticClass:"ml-2",attrs:{"dark":"","small":"","color":"blue"}},[_c(VIcon,{on:{"click":function($event){return _vm.addBonus('call-in')}}},[_vm._v("mdi-cellphone")])],1):_vm._e(),(_vm.formNewRallyValidation)?_c(VBtn,{staticClass:"ml-2",attrs:{"dark":"","small":"","color":"blue"}},[_c(VIcon,{on:{"click":function($event){return _vm.addBonus('tracking')}}},[_vm._v("mdi-radar")])],1):_vm._e(),(_vm.formNewRallyValidation)?_c(VBtn,{staticClass:"ml-2",attrs:{"dark":"","small":"","color":"blue"}},[_c(VIcon,{on:{"click":function($event){return _vm.addBonus('other')}}},[_vm._v("mdi-expand-all-outline")])],1):_vm._e(),(!_vm.formNewRallyValidation)?_c('div',[_vm._v(" Non-mappable bonuses require start and end location to be specified ")]):_vm._e()],1):_vm._e(),(_vm.formNewRallyValidation)?_c(VCardText,[_c(VRow,{staticClass:"ma-2",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"dense":"","disable-pagination":"","hide-default-footer":"","headers":_vm.tableHeaders,"items":_vm.BonusLocationsByCategoryGetter('N'),"item-key":"name"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editBonus(item)}}},[_vm._v("mdi-pencil")]),_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.askDeleteBonus(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.timezoneid",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(((_vm.DateTime.now() .setZone(item.timezoneid) .toFormat('ZZZZ')) + " (" + (item.timezoneid) + ")"))+" ")]}}],null,false,1038519374)})],1)],1)],1):_vm._e(),_c(VCardActions,[_c('div',{staticClass:"caption ml-12 mb-5 grey--text"},[_c(VIcon,{attrs:{"small":"","color":"grey"}},[_vm._v("mdi-identifier")]),_vm._v(" "+_vm._s(_vm.newRally.rallyid)+" ")],1),_c(VSpacer),_c(VBtn,{staticClass:"mb-5",attrs:{"text":"","color":"grey","elevation":"0"},on:{"click":function($event){return _vm.cancelRallyDialog()}}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"mr-5 mb-5 white--text",attrs:{"color":"green","elevation":"0","disabled":!_vm.formNewRallyValidation,"loading":_vm.loadingSaveRallyButton},on:{"click":_vm.saveRally}},[_vm._v("Save rally leg")])],1)],1)],1),_c('dialogAddNonRidingBonus',{attrs:{"newbonus":_vm.newBonus,"newrally":_vm.newRally},model:{value:(_vm.showAddBonusDialog),callback:function ($$v) {_vm.showAddBonusDialog=$$v},expression:"showAddBonusDialog"}}),_c('dialogDelete',{attrs:{"objectid":_vm.objectidToBeDeleted},on:{"delete":_vm.dialogDeleteAction},model:{value:(_vm.showDialogDelete),callback:function ($$v) {_vm.showDialogDelete=$$v},expression:"showDialogDelete"}}),_c('dialogAddressSearch',{attrs:{"editrally":_vm.editrally,"rallylocation":_vm.rallyLocation},on:{"modified":_vm.updateStartStopLatLng},model:{value:(_vm.showAddressSearchDialog),callback:function ($$v) {_vm.showAddressSearchDialog=$$v},expression:"showAddressSearchDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }