export function validateRequired(_inputValidate) {
  if (_inputValidate) {
    return true
  } else {
    return 'Field is required'
  }
}
export function validateWholeNumber(_inputValidate) {
  if (/^[-+]?[0-9]+$/.test(_inputValidate)) {
    return true
  } else {
    return 'Please only enter numeric characters! (Allowed input:0-9)'
  }
}
export function validateHTML(_inputValidate) {
  if (/(<([^>]+)>)/gi.test(_inputValidate)) {
    return 'Please remove HTML/XML tags from input'
  } else {
    return true
  }
}
export function validateCharacters(_inputValidate) {
  if (/[&<>]/g.test(_inputValidate)) {
    return 'Please remove the following characters from input: & < >'
  } else {
    return true
  }
}
export function validateLength(_inputValidate, _maximumLength) {
  if (_inputValidate && _inputValidate.length <= _maximumLength) {
    return true
  } else if (!_inputValidate) {
    return true
  } else {
    return `Please only enter values shorter than ${_maximumLength} characters`
  }
}
export function validateMinimum(_inputValidate, _minimumValue) {
  if (_inputValidate >= _minimumValue) {
    return true
  } else {
    return `Please only enter values greater than ${_minimumValue}`
  }
}
export function validateMaximum(_inputValidate, _maximumValue) {
  if (_inputValidate <= _maximumValue) {
    return true
  } else {
    return `Please only enter values less than ${_maximumValue}`
  }
}
export function validateURLprotocol(_inputValidate) {
  if (_inputValidate && _inputValidate.length > 6) {
    if (/^(http|https):/i.test(_inputValidate)) {
      return true
    } else {
      return 'Please start with either http:// or https://'
    }
  } else if (!_inputValidate) {
    return true
  } else {
    return false
  }
}

export function validateIsURL(_inputValidate) {
  if (!_inputValidate) {
    return true
  } else {
    try {
      new URL(_inputValidate)
    } catch (_) {
      return 'Enter a correct URL'
    }
    return true
  }
}

export function validateIdentical(_inputValidate, _compareValue) {
  if (!_inputValidate) {
    return true
  } else if (_inputValidate === _compareValue) {
    return true
  } else {
    return "You didn't enter the ID correctly"
  }
}

export function validateLandBasedLocation(_inputValidate) {
  if (_inputValidate === false) {
    return true
  } else {
    return 'These coordinates are not for a land-based location'
  }
}

export function sanitizeURL(_inputValidate) {
  let sanitizeUrl = require('@braintree/sanitize-url').sanitizeUrl
  let sanitizedurl = sanitizeUrl(decodeURIComponent(_inputValidate))
  if (sanitizedurl === 'null') sanitizedurl = null
  return sanitizedurl
}
