<template>
  <v-dialog :value="value" max-width="700px">
    <v-card>
      <v-form ref="formNonRidingBonus" v-model="isValid">
        <v-toolbar color="blue" dark flat>
          <v-toolbar-title>Add {{ updatedBonus.symbol }} bonus</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text class="mt-3">
          <v-row dense>
            <v-col cols="4">
              <v-text-field
                v-model="updatedBonus.name"
                label="Bonus ID"
                dense
                :rules="[
                  validateRequired(updatedBonus.name),
                  validateHTML(updatedBonus.name),
                  validateLength(updatedBonus.name, 250),
                  validateCharacters(updatedBonus.name)
                ]"
                @change="updateForm"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="updatedBonus.points"
                label="Points"
                dense
                type="number"
                :rules="[
                  validateRequired(updatedBonus.points),
                  validateWholeNumber(updatedBonus.points),
                  validateMinimum(updatedBonus.points, -100000),
                  validateMaximum(updatedBonus.points, 100000)
                ]"
                @change="updateForm"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="updatedBonus.timezoneid"
                :items="selectTimezones"
                item-text="timezone"
                item-value="timezoneid"
                label="Timezone"
                dense
                :rules="[validateRequired(updatedBonus.timezoneid)]"
                @change="updateForm"
              ></v-select>
            </v-col> </v-row
          ><v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="updatedBonus.comment"
                label="Comment"
                dense
                clearable
                :rules="[
                  validateHTML(updatedBonus.comment),
                  validateLength(updatedBonus.comment, 250),
                  validateCharacters(updatedBonus.comment)
                ]"
                @change="updateForm"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3">
              <v-menu
                v-model="menuBonusStartDate"
                :close-on-content-click="false"
                :nudge-right="32"
                transition="scale-transition"
                offset-y
                min-width="100px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="windowStartDate"
                    label="Start date"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="windowStartDate"
                  :min="windowStartDate"
                  :max="windowEndDate"
                  :picker-date="windowStartDate"
                  show-current
                  @input="menuBonusStartDate = false"
                  @change="updateForm"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-menu
                ref="menuBonusStartTime"
                v-model="menuBonusStartTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="windowStartTime"
                :rules="[validateRequired(updatedBonus.points)]"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="windowStartTime"
                    label="Start time"
                    :rules="[validateRequired(windowStartTime)]"
                    prepend-icon="mdi-clock-outline"
                    readonly
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuBonusStartTime"
                  v-model="windowStartTime"
                  full-width
                  @click:minute="$refs.menuBonusStartTime.save(windowStartTime)"
                  @change="updateForm"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-menu
                v-model="menuBonusEndDate"
                :close-on-content-click="false"
                :nudge-right="32"
                transition="scale-transition"
                offset-y
                min-width="100px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="windowEndDate"
                    label="End date"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    v-on="on"
                    @change="updateForm"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="windowEndDate"
                  :min="windowStartDate"
                  :max="windowEndDate"
                  :picker-date="windowStartDate"
                  show-current
                  @input="menuBonusEndDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-menu
                ref="menuBonusEndTime"
                v-model="menuBonusEndTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="windowEndTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="windowEndTime"
                    label="End time"
                    :rules="[validateRequired(windowEndTime)]"
                    prepend-icon="mdi-clock-outline"
                    readonly
                    dense
                    v-on="on"
                    @change="updateForm"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuBonusEndTime"
                  v-model="windowEndTime"
                  full-width
                  @click:minute="$refs.menuBonusEndTime.save(windowEndTime)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <v-text-field
                v-model="updatedBonus.longname"
                label="Longname"
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="updatedBonus.value"
                label="Value"
                readonly
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-icon>{{ updatedBonus.getMaterialicon() }}</v-icon>
            </v-col>
          </v-row>
          <v-row v-if="updatedBonus.symbol === 'rest'" dense>
            <v-col cols="6">
              <v-text-field
                v-model="updatedBonus.restminduration"
                label="Minimum rest stop duration"
                dense
                :rules="[
                  validateRequired(updatedBonus.restminduration),
                  validateMinimum(updatedBonus.restminduration, 15),
                  validateMaximum(updatedBonus.restminduration, 1440)
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="updatedBonus.restmaxduration"
                label="Maximum rest stop duration"
                dense
                :rules="[
                  validateRequired(updatedBonus.restmaxduration),
                  validateMinimum(
                    updatedBonus.restmaxduration,
                    updatedBonus.restminduration
                  ),
                  validateMaximum(updatedBonus.restmaxduration, 1440)
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="my-4">
            <v-spacer></v-spacer>
            <v-btn color="grey" dark @click="$emit('input', false)"
              >Cancel</v-btn
            >
            <v-btn
              class="ml-7 mr-3 white--text"
              color="green"
              :disabled="!isValid"
              :loading="busySaveNonRidingBonusButton"
              @click="saveNonRidingBonus()"
              >Save bonus</v-btn
            >
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as inputValidationRules from '@/components/inputValidationRules'
import { BonusLocationsPrototype } from '@/components/prototypes/bonuslocations'
import { DateTime } from 'luxon'
export default {
  name: 'DialogAddNonRidingBonus',
  props: {
    value: { required: true, type: Boolean },
    newbonus: { required: true, type: Object },
    newrally: { required: true, type: Object }
  },
  data() {
    const data = {
      updatedBonus: [],
      isValid: false,
      selectTimezones: [],
      menuBonusStartDate: false,
      menuBonusStartTime: false,
      menuBonusEndDate: false,
      menuBonusEndTime: false,
      windowStartDate: new Date().toISOString().substr(0, 10),
      windowStartTime: '00:00',
      windowEndDate: new Date().toISOString().substr(0, 10),
      windowEndTime: '23:59',
      addBonusDialogIcon: null,
      busySaveNonRidingBonusButton: false
    }
    return { ...data, ...inputValidationRules }
  },
  computed: {
    ...mapGetters('moduleBonusLocations', [
      'BonusLocationsByNameGetter',
      'BonusLocationsByCategoryGetter'
    ])
  },
  watch: {
    value: function () {
      // we must use the watch function to see the toggle of 'value' to updated the dailog. This cannot be done with created or mounted
      const nameMap = new Map()
      nameMap.set('rest', 'REST')
      nameMap.set('call-in', 'CALL')
      nameMap.set('tracking', 'TRK')
      nameMap.set('other', 'OTHR')

      this.updatedBonus = { ...this.newbonus }
      this.updatedBonus.__proto__ = BonusLocationsPrototype.prototype
      if (this.updatedBonus.name === null)
        this.updatedBonus.name = `${nameMap.get(this.updatedBonus.symbol)}${
          this.BonusLocationsByCategoryGetter('N').filter(
            (bonusLocation) => bonusLocation.symbol === this.updatedBonus.symbol
          ).length + 1
        }`

      // we need to fill an array with timezone data to be used with the v-select dropdown box
      this.selectTimezones = []
      let start = this.BonusLocationsByNameGetter('START')
      let end = this.BonusLocationsByNameGetter('END')
      this.selectTimezones.push({
        timezoneid: start.timezoneid,
        timezone: DateTime.now().setZone(start.timezoneid).toFormat('ZZZZ')
      })
      this.selectTimezones.push({
        timezoneid: end.timezoneid,
        timezone: DateTime.now().setZone(end.timezoneid).toFormat('ZZZZ')
      })

      if (this.BonusLocationsByNameGetter(this.updatedBonus.name) !== null) {
        // if this is an existing bonus we are editing: let's make sure we parse the timerestictions
        this.windowStartDate = this.updatedBonus.timerestrictions
          .split(' to ')[0]
          .split(' @ ')[0]
        this.windowStartTime = this.updatedBonus.timerestrictions
          .split(' to ')[0]
          .split(' @ ')[1]
        this.windowEndDate = this.updatedBonus.timerestrictions
          .split(' to ')[1]
          .split(' @ ')[0]
        this.windowEndTime = this.updatedBonus.timerestrictions
          .split(' to ')[1]
          .split(' @ ')[1]
      } else {
        // this is a new bonus and thus we set the time restrictions to its defaults (start and end of rally)
        this.windowStartDate = DateTime.fromISO(this.newrally.start.datetime, {
          setZone: true
        }).toFormat('yyyy-LL-dd')
        this.windowStartTime = DateTime.fromISO(this.newrally.start.datetime, {
          setZone: true
        }).toFormat('HH:mm')
        this.windowEndDate = DateTime.fromISO(this.newrally.end.datetime, {
          setZone: true
        }).toFormat('yyyy-LL-dd')
        this.windowEndTime = DateTime.fromISO(this.newrally.end.datetime, {
          setZone: true
        }).toFormat('HH:mm')
      }
    }
  },
  created: function () {
    this.updatedBonus = new BonusLocationsPrototype()
  },
  methods: {
    ...mapActions('moduleBonusLocations', ['BonusLocationsCreateUpdateAction']),
    async saveNonRidingBonus() {
      this.busySaveNonRidingBonusButton = true
      this.BonusLocationsCreateUpdateAction(this.updatedBonus)
      this.updatedBonus = new BonusLocationsPrototype()
      this.busySaveNonRidingBonusButton = false
      this.$emit('input', false)
    },
    updateForm() {
      if (this.updatedBonus.bonusid === null) this.updatedBonus.setID()
      this.updatedBonus.timerestrictions = `${this.windowStartDate} @ ${this.windowStartTime} to ${this.windowEndDate} @ ${this.windowEndTime}`
      this.updatedBonus.category = 'N'
      this.updatedBonus.setLongname()
      this.updatedBonus.points = Number(this.updatedBonus.points)
      this.updatedBonus.setValue()
    }
  }
}
</script>

<style></style>
