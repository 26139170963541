<template>
  <v-dialog v-model="value" hide-overlay persistent width="300">
    <v-card color="primary" dark>
      <v-card-text class="pt-2">
        Please stand by
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogLoading',
  props: {
    value: { required: true, type: Boolean }
  }
}
</script>

<style></style>
