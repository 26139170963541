import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"elevation":"2"}},[_c(VToolbar,{attrs:{"flat":"","color":"blue","dark":""}},[_c(VToolbarTitle,[_vm._v("Active rally leg")])],1),(!_vm.activeRally.rallyid)?_c(VCardText,[_c(VAlert,{staticClass:"mt-3",attrs:{"type":"info","color":"grey darken-2"}},[_vm._v(" Please create a new rally or select one from the table below.")])],1):_vm._e(),(_vm.activeRally.rallyid !== null)?_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VTooltip,{attrs:{"right":"","max-width":"220"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-identifier")]),_vm._v(_vm._s(_vm.activeRally ? _vm.activeRally.rallyid : '')+" ")]}}],null,false,1054293157)},[_c('span',[_vm._v("Rally leg identification number")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-counter")]),_vm._v(_vm._s(_vm.activeRally ? _vm.activeRally.legid : ''))]}}],null,false,2321885661)},[_c('span',[_vm._v("Rally leg number")])])],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-map-marker")]),_vm._v(_vm._s(_vm.activeRally ? _vm.BonusLocationsCountGetter : ''))]}}],null,false,2803661132)},[_c('span',[_vm._v("Total number of bonuses in this rally leg")])])],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VTooltip,{attrs:{"right":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-highway")]),_vm._v(_vm._s(_vm.activeRally ? _vm.activeRally.rallyname : ''))]}}],null,false,2171776198)},[_c('span',[_vm._v("Rally leg name")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-clock-start")]),_vm._v(" "+_vm._s(_vm.activeRally ? _vm.DateTime.fromISO(_vm.activeRally.start.datetime, { setZone: true }).toFormat('yyyy-LL-dd') : '')+" @ "+_vm._s(_vm.activeRally ? _vm.DateTime.fromISO(_vm.activeRally.start.datetime, { setZone: true }).toFormat('HH:mm') : '')+" ")]}}],null,false,1994272979)},[_c('span',[_vm._v("Start of rally leg timing")])])],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-clock-digital")]),_vm._v(" "+_vm._s(_vm.activeRally.getRallyduration()))]}}],null,false,3473857054)},[_c('span',[_vm._v("Rally leg duration")])])],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VTooltip,{attrs:{"right":"","max-width":"220"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-form-textbox")]),_vm._v(_vm._s(_vm.activeRally && _vm.activeRally.description ? _vm.activeRally.description : 'No description provided'))]}}],null,false,545013569)},[_c('span',[_vm._v("Rally leg description")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"220"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-clock-end")]),_vm._v(" "+_vm._s(_vm.activeRally ? _vm.DateTime.fromISO(_vm.activeRally.end.datetime, { setZone: true }).toFormat('yyyy-LL-dd') : '')+" @ "+_vm._s(_vm.activeRally ? _vm.DateTime.fromISO(_vm.activeRally.end.datetime, { setZone: true }).toFormat('HH:mm') : '')+" ")]}}],null,false,4284682460)},[_c('span',[_vm._v("End of rally leg timing")])])],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-clock-alert-outline")]),_vm._v(_vm._s(_vm.activeRally ? _vm.activeRally.penaltywindow + ' minutes' : '')+" ")]}}],null,false,171491622)},[_c('span',[_vm._v("Length of penalty window beyond end time")])])],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VTooltip,{attrs:{"right":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-file-link-outline")]),(_vm.activeRally.getUrl().host !== 'ldrallyrideplanner.com')?_c('a',{attrs:{"target":"_blank","href":_vm.activeRally.getUrl().href}},[_vm._v(_vm._s(_vm.activeRally.getUrl().host))]):_c('span',[_vm._v("No rally website provided")])]}}],null,false,2768253497)},[_c('span',[_vm._v("Rally website")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"220"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-account")]),_vm._v(_vm._s(_vm.activeRally ? _vm.activeRally.createdname : ''))]}}],null,false,2221243116)},[_c('span',[_vm._v("Person that entered the rally leg data")])])],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VTooltip,{attrs:{"left":"","max-width":"220"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-calendar")]),_vm._v(_vm._s(_vm.activeRally.createdon ? _vm.activeRally.createdon.substring(0, 10) : '')+" ")]}}],null,false,2243882814)},[_c('span',[_vm._v("Date when rally leg data was entered")])])],1)],1),_c(VDivider,{staticClass:"mt-5"}),(_vm.BonusLocationsByCategoryGetter('N').length > 0)?_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"dense":"","disable-pagination":"","hide-default-footer":"","headers":_vm.tableHeadersRally,"items":_vm.BonusLocationsByCategoryGetter('N'),"item-key":"name"},scopedSlots:_vm._u([{key:"item.timezoneid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.timezoneid) + " (" + (_vm.DateTime.now() .setZone(item.timezoneid) .toFormat('ZZZZ')) + ")"))+" ")]}}],null,false,647562735)})],1)],1):_vm._e(),_c(VRow,{staticClass:"pt-5"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"dense":"","disable-pagination":"","hide-default-footer":"","headers":_vm.tableHeadersFiles,"items":_vm.rallyFiles,"item-key":"name"},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.DateTime.fromISO(item.time).toFormat('MM/dd HH:mm ZZZZ'))+" ")]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(Number(item.size) / 1000000).toFixed(2))+" MB ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"small":"","icon":"","target":"_blank","href":item.url}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-download")])],1),_c(VBtn,{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.askDeleteFile(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,false,181317902)})],1),_c(VCol,{staticClass:"text-right"},[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.addFile}},[_vm._v("Add file")])],1)],1)],1):_vm._e()],1),_c(VCard,{staticClass:"mt-12",attrs:{"elevation":"2"}},[_c(VToolbar,{attrs:{"flat":"","color":"blue","dark":""}},[_c(VToolbarTitle,[_vm._v("Your rallies")]),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,{staticClass:"ml-5",attrs:{"icon":""},on:{"click":function($event){return _vm.TEST()}}},[_c(VIcon,_vm._g({attrs:{"color":"yellow"}},on),[_vm._v("mdi-nuke")])],1)]}}])},[_c('span',[_vm._v("Test something")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,{staticClass:"ml-5",attrs:{"icon":""},on:{"click":function($event){return _vm.addRally()}}},[_c(VIcon,_vm._g({attrs:{"c":""}},on),[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Create a new rally")])])],1),_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c(VDataTable,{attrs:{"headers":_vm.tableHeadersRallies,"items":_vm.Rallies,"sort-by":['rallyname', 'legid'],"multi-sort":"","item-key":"id","dense":""},on:{"click:row":_vm.loadRally},scopedSlots:_vm._u([{key:"item.createdon",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.activeRally.createdon ? item.createdon.substring(0, 10) : '')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"loading":_vm.busyDeleteRallyButton,"small":"","icon":""},on:{"click":function($event){return _vm.askDeleteRally(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-delete")])],1),_c(VBtn,{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.editExistingRally(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}])})],1)],1)],1)],1),_c('dialogAddRally',{attrs:{"editrally":_vm.editRally},model:{value:(_vm.showRallyDialog),callback:function ($$v) {_vm.showRallyDialog=$$v},expression:"showRallyDialog"}}),_c('dialogDelete',{attrs:{"objectid":_vm.objectidToBeDeleted},on:{"delete":_vm.dialogDeleteAction},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}}),_c('dialogLoading',{model:{value:(_vm.showLoadingDialog),callback:function ($$v) {_vm.showLoadingDialog=$$v},expression:"showLoadingDialog"}}),_c('dialogAddFile',{attrs:{"files":_vm.filesToBeUploaded,"multiple":true},on:{"input":_vm.listFileDirectory},model:{value:(_vm.showAddFileDialog),callback:function ($$v) {_vm.showAddFileDialog=$$v},expression:"showAddFileDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }