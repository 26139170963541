<template>
  <v-container>
    <v-card elevation="2">
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>Active rally leg</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-if="!activeRally.rallyid">
        <v-alert type="info" color="grey darken-2" class="mt-3">
          Please create a new rally or select one from the table below.</v-alert
        >
      </v-card-text>
      <v-card-text v-if="activeRally.rallyid !== null">
        <v-row dense>
          <v-col cols="6">
            <v-tooltip right max-width="220">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" v-on="on">mdi-identifier</v-icon
                >{{ activeRally ? activeRally.rallyid : '' }} </template
              ><span>Rally leg identification number</span></v-tooltip
            ></v-col
          >
          <v-col cols="4">
            <v-tooltip left max-width="180">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" v-on="on">mdi-counter</v-icon
                >{{ activeRally ? activeRally.legid : '' }}</template
              ><span>Rally leg number</span></v-tooltip
            ></v-col
          >
          <v-col cols="2">
            <v-tooltip left max-width="180">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" v-on="on">mdi-map-marker</v-icon
                >{{ activeRally ? BonusLocationsCountGetter : '' }}</template
              ><span>Total number of bonuses in this rally leg</span></v-tooltip
            ></v-col
          >
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-tooltip right max-width="180">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" v-on="on">mdi-highway</v-icon
                >{{ activeRally ? activeRally.rallyname : '' }}</template
              ><span>Rally leg name</span></v-tooltip
            >
          </v-col>
          <v-col cols="4">
            <v-tooltip left max-width="180">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" v-on="on">mdi-clock-start</v-icon>
                {{
                  activeRally
                    ? DateTime.fromISO(activeRally.start.datetime, {
                        setZone: true
                      }).toFormat('yyyy-LL-dd')
                    : ''
                }}
                @
                {{
                  activeRally
                    ? DateTime.fromISO(activeRally.start.datetime, {
                        setZone: true
                      }).toFormat('HH:mm')
                    : ''
                }} </template
              ><span>Start of rally leg timing</span></v-tooltip
            >
          </v-col>
          <v-col cols="2">
            <v-tooltip left max-width="180">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" v-on="on">mdi-clock-digital</v-icon>
                {{ activeRally.getRallyduration() }}</template
              ><span>Rally leg duration</span></v-tooltip
            ></v-col
          >
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-tooltip right max-width="220">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" v-on="on">mdi-form-textbox</v-icon
                >{{
                  activeRally && activeRally.description
                    ? activeRally.description
                    : 'No description provided'
                }}</template
              ><span>Rally leg description</span></v-tooltip
            >
          </v-col>
          <v-col cols="4">
            <v-tooltip left max-width="220">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" v-on="on">mdi-clock-end</v-icon>
                {{
                  activeRally
                    ? DateTime.fromISO(activeRally.end.datetime, {
                        setZone: true
                      }).toFormat('yyyy-LL-dd')
                    : ''
                }}
                @
                {{
                  activeRally
                    ? DateTime.fromISO(activeRally.end.datetime, {
                        setZone: true
                      }).toFormat('HH:mm')
                    : ''
                }} </template
              ><span>End of rally leg timing</span></v-tooltip
            >
          </v-col>
          <v-col cols="2">
            <v-tooltip left max-width="180">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" v-on="on">mdi-clock-alert-outline</v-icon
                >{{
                  activeRally ? activeRally.penaltywindow + ' minutes' : ''
                }} </template
              ><span>Length of penalty window beyond end time</span></v-tooltip
            ></v-col
          >
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-tooltip right max-width="180">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" v-on="on">mdi-file-link-outline</v-icon>
                <a
                  v-if="activeRally.getUrl().host !== 'ldrallyrideplanner.com'"
                  target="_blank"
                  :href="activeRally.getUrl().href"
                  >{{ activeRally.getUrl().host }}</a
                >
                <span v-else>No rally website provided</span> </template
              ><span>Rally website</span></v-tooltip
            ></v-col
          >
          <v-col cols="4">
            <v-tooltip left max-width="220">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" v-on="on">mdi-account</v-icon
                >{{ activeRally ? activeRally.createdname : '' }}</template
              ><span>Person that entered the rally leg data</span></v-tooltip
            >
          </v-col>
          <v-col cols="2">
            <v-tooltip left max-width="220">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" v-on="on">mdi-calendar</v-icon
                >{{
                  activeRally.createdon
                    ? activeRally.createdon.substring(0, 10)
                    : ''
                }} </template
              ><span>Date when rally leg data was entered</span></v-tooltip
            ></v-col
          >
        </v-row>
        <v-divider class="mt-5"></v-divider>
        <v-row v-if="BonusLocationsByCategoryGetter('N').length > 0" dense>
          <v-col cols="12">
            <v-data-table
              dense
              disable-pagination
              hide-default-footer
              :headers="tableHeadersRally"
              :items="BonusLocationsByCategoryGetter('N')"
              item-key="name"
            >
              <template v-slot:item.timezoneid="{ item }">
                {{
                  `${item.timezoneid} (${DateTime.now()
                    .setZone(item.timezoneid)
                    .toFormat('ZZZZ')})`
                }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col cols="12">
            <v-data-table
              dense
              disable-pagination
              hide-default-footer
              :headers="tableHeadersFiles"
              :items="rallyFiles"
              item-key="name"
            >
              <template v-slot:item.time="{ item }">
                {{ DateTime.fromISO(item.time).toFormat('MM/dd HH:mm ZZZZ') }}
              </template>
              <template v-slot:item.size="{ item }">
                {{ Number(Number(item.size) / 1000000).toFixed(2) }} MB
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn small icon target="_blank" :href="item.url"
                  ><v-icon small>mdi-download</v-icon></v-btn
                >
                <v-btn small icon @click="askDeleteFile(item)"
                  ><v-icon small>mdi-delete</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-col>
          <v-col class="text-right">
            <v-spacer /> <v-btn text @click="addFile">Add file</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card elevation="2" class="mt-12">
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>Your rallies</v-toolbar-title><v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon class="ml-5" @click="TEST()">
              <v-icon color="yellow" v-on="on">mdi-nuke</v-icon></v-btn
            > </template
          ><span>Test something</span></v-tooltip
        >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon class="ml-5" @click="addRally()">
              <v-icon c v-on="on">mdi-pencil</v-icon></v-btn
            > </template
          ><span>Create a new rally</span></v-tooltip
        >
      </v-toolbar>
      <v-card-text>
        <v-row dense>
          <v-col>
            <v-data-table
              :headers="tableHeadersRallies"
              :items="Rallies"
              :sort-by="['rallyname', 'legid']"
              multi-sort
              item-key="id"
              dense
              @click:row="loadRally"
            >
              <template v-slot:item.createdon="{ item }">
                {{
                  activeRally.createdon ? item.createdon.substring(0, 10) : ''
                }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  :loading="busyDeleteRallyButton"
                  small
                  icon
                  @click="askDeleteRally(item)"
                  ><v-icon small>mdi-delete</v-icon></v-btn
                >
                <v-btn small icon @click="editExistingRally(item)"
                  ><v-icon small>mdi-pencil</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text></v-card
    >
    <dialogAddRally v-model="showRallyDialog" :editrally="editRally" />
    <dialogDelete
      v-model="showDeleteDialog"
      :objectid="objectidToBeDeleted"
      @delete="dialogDeleteAction"
    />
    <dialogLoading v-model="showLoadingDialog" />
    <dialogAddFile
      v-model="showAddFileDialog"
      :files="filesToBeUploaded"
      :multiple="true"
      @input="listFileDirectory"
    />
  </v-container>
</template>

<script>
/**
 * @typedef {Object} FirestoreFile
 * @author Patrick Nijsters
 * @global
 * @property {String} name The name of the file
 * @property {String} url The URL where the file can be downloaded from
 * @property {String} fullpath The full Firestore path
 * @property {String} contenttype The content type of the stored file
 * @property {String} size The size of the file in bytes
 * @property {String} time The time the file was created in Firestore
 */

/**
 * @module Home
 */
import { mapState, mapActions, mapGetters } from 'vuex'
import { storage } from '@/firebaseConfig.js'
import dialogAddRally from '@/components/dialogs/dialogAddRally'
import dialogDelete from '@/components/dialogs/dialogDelete'
import dialogLoading from '@/components/dialogs/dialogLoading'
import dialogAddFile from '@/components/dialogs/dialogAddFile'
import { RalliesPrototype } from '@/components/prototypes/rallies'
import * as Luxon from 'luxon'
export default {
  name: 'Home',
  components: {
    dialogAddRally,
    dialogDelete,
    dialogLoading,
    dialogAddFile
  },
  data() {
    const data = {
      objectidToBeDeleted: '',
      filesToBeUploaded: [],
      rallyFiles: [],
      showRallyDialog: false,
      showLoadingDialog: false,
      showAddFileDialog: false,
      dialogAddRally: false,
      busyDeleteRallyButton: false,
      showDeleteDialog: false,
      editRally: null,
      tableHeadersRallies: [
        { text: 'Name', sortable: false, value: 'rallyname' },
        { text: 'Leg', sortable: false, value: 'legid' },
        { text: 'Description', sortable: false, value: 'description' },
        { text: 'Created on', sortable: false, value: 'createdon' },
        { text: 'Actions', sortable: false, value: 'actions' }
      ],
      tableHeadersRally: [
        { text: 'Bonus ID', sortable: true, value: 'name' },
        { text: 'Points', sortable: false, value: 'points' },
        { text: 'Value', sortable: false, value: 'value' },
        { text: 'Timezone', sortable: false, value: 'timezoneid' },
        { text: 'Bonus window', sortable: false, value: 'timerestrictions' }
      ],
      tableHeadersFiles: [
        { text: 'File name', sortable: true, value: 'name' },
        { text: 'File type', sortable: false, value: 'type' },
        { text: 'File timestamp', sortable: false, value: 'time' },
        { text: 'File size', sortable: false, value: 'size' },
        { text: 'Actions', sortable: false, value: 'actions' }
      ]
    }
    return { ...data, ...Luxon }
  },
  computed: {
    ...mapGetters('moduleBonusLocations', [
      'BonusLocationsByCategoryGetter',
      'BonusLocationsCountGetter'
    ]),
    ...mapGetters('moduleRallies', ['RalliesGetRallyByIdGetter']),
    ...mapState({
      CurrentUser: (state) => state.moduleUser.CurrentUser,
      UserProfile: (state) => state.moduleUser.UserProfile,
      Rallies: (state) => state.moduleRallies.Rallies,
      Routes: (state) => state.moduleRoutes.Routes
    }),
    activeRally() {
      return this.RalliesGetRallyByIdGetter(this.UserProfile.activerallyid)
    }
  },
  created: async function () {
    this.RalliesReadAllAction()
  },
  mounted: function () {
    this.listFileDirectory()
  },
  methods: {
    ...mapActions('moduleBonusLocations', [
      'BonusLocationsReadAllAction',
      'BonusLocationsDeleteByCategoryAction'
    ]),
    ...mapActions('moduleRallies', [
      'RalliesDeleteAction',
      'RalliesReadAllAction'
    ]),
    ...mapActions('moduleRouteStops', [
      'RouteStopsClearAction',
      'RouteStopsDeleteAllAction',
      'RouteStopsReadAllAction'
    ]),
    ...mapActions('moduleUser', [
      'UserProfileSetActiveRallyAction',
      'UserProfileSetActiveRouteAction'
    ]),
    ...mapActions('moduleRoutes', [
      'RoutesClearAction',
      'RoutesDeleteAllAction'
    ]),
    async TEST() {},

    /**
     * @author Patrick Nijsters
     * @memberof module:Home
     * @returns {Array.<FirestoreFile>}
     */
    async listFileDirectory() {
      if (!this.UserProfile.activerallyid) return
      this.rallyFiles = []
      let directoryContent = await storage
        .ref(
          `users/${this.CurrentUser.uid}/rallybooks/${this.UserProfile.activerallyid}`
        )
        .listAll()
      directoryContent.items.forEach(async (file) => {
        let downloadURL = await storage.ref(file.fullPath).getDownloadURL()
        let fileMetaData = await storage.ref(file.fullPath).getMetadata()
        this.rallyFiles.push({
          name: file.name,
          url: downloadURL,
          path: fileMetaData.fullPath,
          type: fileMetaData.contentType,
          size: fileMetaData.size,
          time: fileMetaData.timeCreated
        })
      })
    },

    /**
     * @author Patrick Nijsters
     * @memberof module:Home
     * @returns {Void}
     */
    async dialogDeleteAction(_event) {
      // delete only a single file
      if (_event === 'file') {
        const file = this.rallyFiles.find(
          (rallyfile) => rallyfile.name === this.objectidToBeDeleted
        )
        await storage.ref(file.path).delete()
        this.rallyFiles.splice(this.rallyFiles.indexOf(file), 1)
      }
      //delete rally including all files in a directory
      if (_event === 'rally') {
        this.busyDeleteRallyButton = true
        this.rallyFiles.forEach(async (file) => {
          storage.ref(file.path).delete()
        })
        this.rallyFiles = []
        await this.RoutesDeleteAllAction()
        await this.RouteStopsClearAction()
        await this.BonusLocationsDeleteByCategoryAction({
          rallyid: this.objectidToBeDeleted,
          categories: ['A', 'D', 'T', 'S', 'N', 'AC', 'DC', 'TC']
        })
        await this.RalliesDeleteAction(this.objectidToBeDeleted)
        await this.UserProfileSetActiveRallyAction(null)
        this.busyDeleteRallyButton = false
      }
      this.objectidToBeDeleted = ''
    },

    /**
     * @author Patrick Nijsters
     * @memberof module:Home
     * @param {FirestoreFile}
     * @returns {Void}
     */
    askDeleteFile(_file) {
      this.objectidToBeDeleted = _file.name
      this.showDeleteDialog = true
    },

    /**
     * @author Patrick Nijsters
     * @memberof module:Home
     * @param {RalliesPrototype}
     * @returns {Void}
     */
    askDeleteRally(_rally) {
      this.objectidToBeDeleted = _rally.rallyid
      this.showDeleteDialog = true
    },

    /**
     * @author Patrick Nijsters
     * @memberof module:Home
     * @param {RalliesPrototype}
     * @returns {Void}
     */
    editExistingRally(_rally) {
      this.editRally = _rally
      this.showRallyDialog = true
    },

    /**
     * @author Patrick Nijsters
     * @memberof module:Home
     * @returns {Void}
     */
    addRally() {
      this.editRally = new RalliesPrototype()
      this.showRallyDialog = true
    },

    /**
     * @author Patrick Nijsters
     * @memberof module:Home
     * @returns {Void}
     */
    addFile() {
      this.filesToBeUploaded = []
      this.showAddFileDialog = true
    },

    /**
     * @author Patrick Nijsters
     * @memberof module:Home
     * @param {RalliesPrototype}
     * @returns {Void}
     */
    async loadRally(_rally) {
      this.showLoadingDialog = true
      this.RouteStopsClearAction()
      this.RoutesClearAction()
      await this.UserProfileSetActiveRallyAction(_rally.rallyid)
      await this.UserProfileSetActiveRouteAction(null)
      await this.BonusLocationsReadAllAction(_rally.rallyid)
      await this.listFileDirectory()
      this.showLoadingDialog = false
    }
  }
}
</script>
