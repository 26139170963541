import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,{attrs:{"elevation":"2"}},[_c(VToolbar,{attrs:{"flat":"","color":"blue","dark":""}},[_c(VToolbarTitle,[_vm._v("Upload file ")])],1),_c(VCardText,{staticClass:"mt-6 body-1"},[_c(VSheet,{attrs:{"rounded":"","color":"grey lighten-4"},on:{"click":function($event){return _vm.$refs.filebtn.click()}}},[_c('div',{ref:"dropzone",staticClass:"dropzone",on:{"dragover":function($event){$event.preventDefault();},"dragleave":_vm.dragleave,"dragenter":_vm.dragenter,"drop":_vm.drop}},[_c('div',{staticClass:"py-5 text--secondary"},[_vm._v(" Drag and drop files here or click to select files ")]),_c(VIcon,{staticClass:"pb-5",attrs:{"x-large":""}},[_vm._v("mdi-cloud-upload")]),_c('div',{staticClass:"input-container"}),_c('input',{ref:"filebtn",staticClass:"filebtn",attrs:{"type":"file","multiple":_vm.multiple,"accept":_vm.validatedAccept &&
              _vm.validatedAccept.extensions.concat( _vm.validatedAccept.mimetypes
              ).join(',')},on:{"input":_vm.upload}})],1)])],1),_c(VCardText,[_c(VChipGroup,{attrs:{"column":""}},_vm._l((_vm.files),function(file){return _c(VChip,{key:file.name,attrs:{"close":"","small":"","color":"blue lighten-4"},on:{"click:close":function($event){return _vm.remove(file)}}},[_vm._v(" "+_vm._s(file.name))])}),1)],1),_c(VCardActions,{staticClass:"mx-2"},[(_vm.files.length > 0)?_c(VBtn,{staticClass:"mb-5",attrs:{"block":"","loading":_vm.busySaving,"elevation":"0","color":"blue white--text"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Submit")]):_vm._e(),(_vm.files.length === 0)?_c(VBtn,{staticClass:"mb-5",attrs:{"block":"","elevation":"0","color":"grey"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancel")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }