<template>
  <v-dialog v-model="value" persistent max-width="600px">
    <v-card>
      <v-toolbar color="blue" dark flat>
        <v-toolbar-title class="ml-5">Address search</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <GmapAutocomplete
                :value="googleAutoCompleteLocation"
                class="form-control blue lighten-5 pa-2 elevation-2"
                style="width: 100%"
                :select-first-on-enter="true"
                placeholder="Enter address or landmark..."
                :options="{
                  geocode: true,
                  fields: ['geometry']
                }"
                @place_changed="autoCompleteAddressMarker"
              >
              </GmapAutocomplete>
              <small class="form-text text-muted"
                >Enter address tor drag map marker to determine latitude and
                longitude</small
              >
            </v-col>
          </v-row>
          <v-row v-if="error"
            ><v-col>
              <v-alert type="error">
                {{ error }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <GmapMap
                ref="mapRef"
                :options="defaultMapOptions"
                :center="googleMarker"
                :zoom="10"
                style="width: 100%; height: 250px"
                @click="geolocateUpdateMarker($event.latLng)"
              >
                <GmapMarker
                  key="1"
                  :position="googleMarker"
                  :clickable="true"
                  :draggable="true"
                  @dragend="geolocateUpdateMarker($event.latLng)"
                />
              </GmapMap>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ml-3 mb-5 mr-2" color="grey" text @click="cancelDialog()"
          >Cancel</v-btn
        >
        <v-btn
          color="green"
          class="ml-3 mb-5 mr-7 white--text"
          elevation="=0"
          :disabled="!locationHasBeenPicked"
          @click="saveLocation()"
          >Use this location</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { RalliesPrototype } from '@/components/prototypes/rallies'
export default {
  name: 'DialogAddressSearch',
  props: {
    value: { required: true, type: Boolean },
    editrally: { required: true, type: Object },
    rallylocation: { required: true, type: String }
  },
  data() {
    return {
      newRally: {},
      error: null,
      googleAutoCompleteLocation: null,
      googleMarker: { lat: 44.902517, lng: -92.783133 },
      locationHasBeenPicked: false,
      defaultMapOptions: {
        controlSize: 20,
        clickableIcons: true,
        streetViewControl: false,
        fullscreenControl: false,
        panControlOptions: false,
        gestureHandling: 'greedy',
        mapTypeId: 'roadmap'
      }
    }
  },
  computed: {
    ...mapState({
      Preferences: (state) => state.modulePreferences.Preferences
    })
  },
  watch: {
    value: function () {
      if (
        (this.editrally.start.latitude === null) |
        (this.editrally.start.longitude === null) |
        (this.editrally.end.latitude === null) |
        (this.editrally.end.longitude === null)
      ) {
        this.googleMarker.lat = 29.536697403527725
        this.googleMarker.lng = -95.57980809975119
      } else if (this.rallylocation === 'start') {
        this.googleMarker.lat = Number(this.editrally.start.latitude)
        this.googleMarker.lng = Number(this.editrally.start.longitude)
      } else if (this.rallylocation === 'end') {
        this.googleMarker.lat = Number(this.editrally.end.latitude)
        this.googleMarker.lng = Number(this.editrally.end.longitude)
      }
    }
  },
  methods: {
    cancelDialog() {
      this.$emit('input', false)
    },
    async checkWaterLocation(_latitude, _longitude) {
      // OnWater API is no longer online!!!!
      const axios = require('axios')
      let axiosQuery = `https://api.onwater.io/api/v1/results/${_latitude},${_longitude}?access_token=${this.Preferences.secrets.ONW_API_KEY}`
      let axiosResult = await axios.get(axiosQuery)
      return axiosResult.data.water
    },
    async saveLocation() {
      // OnWater API is no longer online!!!!
      // if (
      //   (await this.checkWaterLocation(
      //     this.googleMarker.lat,
      //     this.googleMarker.lng
      //   )) === true
      // ) {
      //   this.error = "You can't ride there! Please pick a land-based location."
      //   return
      // }
      this.locationHasBeenPicked = false
      this.$emit('modified', this.newRally)
      this.$emit('input', false)
    },
    geolocateUpdateMarker(_marker) {
      this.newRally = JSON.parse(JSON.stringify(this.editrally))
      this.newRally.__proto__ = RalliesPrototype.prototype
      this.locationHasBeenPicked = true
      if (this.rallylocation === 'start') {
        this.newRally.start.latitude = _marker.lat()
        this.newRally.start.longitude = _marker.lng()
      } else if (this.rallylocation === 'end') {
        this.newRally.end.latitude = _marker.lat()
        this.newRally.end.longitude = _marker.lng()
      }
      this.googleMarker.lat = _marker.lat()
      this.googleMarker.lng = _marker.lng()
    },
    autoCompleteAddressMarker(_marker) {
      this.newRally = JSON.parse(JSON.stringify(this.editrally))
      this.newRally.__proto__ = RalliesPrototype.prototype
      this.locationHasBeenPicked = true
      this.googleMarker.lat = _marker.geometry.location.lat()
      this.googleMarker.lng = _marker.geometry.location.lng()

      if (this.rallylocation === 'start') {
        this.newRally.start.latitude = Number(_marker.geometry.location.lat())
        this.newRally.start.longitude = Number(_marker.geometry.location.lng())
      } else if (this.rallylocation === 'end') {
        this.newRally.end.latitude = Number(_marker.geometry.location.lat())
        this.newRally.end.longitude = Number(_marker.geometry.location.lng())
      }
    }
  }
}
</script>

<style></style>
