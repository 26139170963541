<template>
  <v-dialog v-model="value" max-width="600px" persistent>
    <v-card elevation="2">
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>Upload file </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-6 body-1">
        <v-sheet rounded color="grey lighten-4" @click="$refs.filebtn.click()">
          <div
            ref="dropzone"
            class="dropzone"
            @dragover.prevent
            @dragleave="dragleave"
            @dragenter="dragenter"
            @drop="drop"
          >
            <div class="py-5 text--secondary">
              Drag and drop files here or click to select files
            </div>
            <v-icon x-large class="pb-5">mdi-cloud-upload</v-icon>
            <div class="input-container"></div>
            <input
              ref="filebtn"
              class="filebtn"
              type="file"
              :multiple="multiple"
              :accept="
                validatedAccept &&
                [
                  ...validatedAccept.extensions,
                  ...validatedAccept.mimetypes
                ].join(',')
              "
              @input="upload"
            />
          </div>
        </v-sheet>
      </v-card-text>
      <v-card-text>
        <v-chip-group column>
          <v-chip
            v-for="file in files"
            :key="file.name"
            close
            small
            color="blue lighten-4"
            @click:close="remove(file)"
          >
            {{ file.name }}</v-chip
          >
        </v-chip-group>
      </v-card-text>
      <v-card-actions class="mx-2">
        <v-btn
          v-if="files.length > 0"
          block
          :loading="busySaving"
          elevation="0"
          class="mb-5"
          color="blue white--text"
          @click="close()"
          >Submit</v-btn
        >
        <v-btn
          v-if="files.length === 0"
          block
          elevation="0"
          class="mb-5"
          color="grey"
          @click="close()"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { storage } from '@/firebaseConfig.js'
import { mapState } from 'vuex'
export default {
  props: {
    value: { required: true, type: Boolean },
    files: {
      type: Array,
      required: true
    },
    accept: {
      type: String,
      required: false,
      default: ''
    },
    multiple: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      hoverCounter: 0,
      busySaving: false,
      hoveringContent: null,
      uploadFileFireStore: '',
      matchAnything: /.*/
    }
  },
  computed: {
    ...mapState({
      CurrentUser: (state) => state.moduleUser.CurrentUser,
      UserProfile: (state) => state.moduleUser.UserProfile
    }),
    filebtn: {
      cache: false,
      get() {
        return this.$refs.filebtn
      }
    },
    dropzone: {
      cache: false,
      get() {
        return this.$refs.dropzone
      }
    },
    validTypes() {
      if (this.validatedAccept) {
        return {
          extensions: this.validatedAccept.extensions
            .map((ext) => ext.replace(/(\W)/g, '\\$1'))
            .map((rgxstr) => new RegExp(`${rgxstr}$`, 'i')),
          mimetypes: this.validatedAccept.mimetypes
            .map((mt) => mt.replace(/([-+/])/g, '\\$1'))
            .map((mt) => mt.replace(/\*/g, '(?:[A-Za-z0-9\\-\\+]*)*'))
            .map((rgxstr) => new RegExp(`^${rgxstr}$`))
        }
      } else {
        return {
          extensions: [this.matchAnything],
          mimetypes: [this.matchAnything]
        }
      }
    },
    validatedAccept() {
      if (this.accept) {
        return {
          extensions: this.accept
            .split(',')
            .filter((type) => type.match(/^\.(?!.*\/)/)),
          mimetypes: this.accept
            .split(',')
            .filter((type) =>
              type.match(
                /^(?:(?:[A-Za-z0-9\-+]*)|\*)\/(?:(?:[A-Za-z0-9\-+.]*)|\*)$/
              )
            )
        }
      } else {
        return null
      }
    }
  },

  watch: {
    multiple(val) {
      if (!val) {
        this.files.splice(0, this.files.length - 1)
      }
    },
    hoveringContent(val) {
      //https://gist.github.com/sploders101/f1a3bb46ed4b8a5897d2153a846addc9#file-filedrop-vue
      if (val) {
        if (
          this.accept &&
          this.accept.length &&
          this.validTypes.extensions.length === 0
        ) {
          let shouldDim = false
          for (let i = 0; i < val.length; i++) {
            for (let j in this.validTypes.mimetypes) {
              if (val[i].type.match(this.validTypes.mimetypes[j])) {
                shouldDim = true
              }
            }
          }
          if (shouldDim) {
            this.dropzone.style.backgroundColor = 'rgba(0, 0, 0, 0.25)'
          }
        } else {
          let shouldDim = false
          for (let i = 0; i < val.length; i++) {
            if (val[i].kind === 'file') {
              shouldDim = true
              break
            }
          }
          if (shouldDim) {
            this.dropzone.style.backgroundColor = 'rgba(0, 0, 0, 0.25)'
          }
        }
      } else {
        this.dropzone.style.backgroundColor = ''
      }
    },
    hoverCounter(val) {
      if (val === 0) {
        this.hoveringContent = null
      }
    }
  },
  methods: {
    upload() {
      const files = this.filebtn.files ?? []
      for (let i = 0; i < files.length; i++) {
        if (!this.multiple) {
          this.files.splice(0, this.files.length)
        }
        const shouldPush =
          this.validTypes.extensions.reduce(
            (prev, regex) => prev || !!files[i].name.match(regex),
            false
          ) ||
          this.validTypes.mimetypes.reduce(
            (prev, regex) => prev || !!files[i].type.match(regex),
            false
          )
        if (shouldPush) {
          this.files.push(files[i])
        }
      }
      this.filebtn.value = ''
    },
    dragenter(e) {
      this.hoveringContent = e.dataTransfer.items
      this.hoverCounter++
    },
    dragleave() {
      this.hoverCounter--
    },
    drop(e) {
      e.preventDefault()
      this.hoverCounter = 0
      if (e.dataTransfer.items) {
        const rejected = []
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          if (e.dataTransfer.items[i].kind === 'file') {
            if (e.dataTransfer.items[i].webkitGetAsEntry) {
              const entry = e.dataTransfer.items[i].webkitGetAsEntry()
              if (entry.isDirectory) {
                rejected.push(entry.name)
                continue
              }
            }
            const file = e.dataTransfer.items[i].getAsFile()
            if (file) {
              const shouldPush =
                this.validTypes.extensions.reduce(
                  (prev, regex) => prev || !!file.name.match(regex),
                  false
                ) ||
                this.validTypes.mimetypes.reduce(
                  (prev, regex) => prev || !!file.type.match(regex),
                  false
                )
              if (shouldPush) {
                if (this.multiple) {
                  this.files
                    .filter((currFile) => currFile.name === file.name)
                    .forEach((fileToRemove) =>
                      this.files.splice(this.files.indexOf(fileToRemove), 1)
                    )
                } else {
                  this.files.splice(0, this.files.length)
                }
                this.files.push(file)
              } else {
                rejected.push(file)
                continue
              }
            } else {
              continue
            }
          }
        }
      }
    },
    async close() {
      if (this.files.length === 0) this.$emit('input', false)
      this.busySaving = true
      for (let index in this.files) {
        await storage
          .ref(
            `users/${this.CurrentUser.uid}/rallybooks/${this.UserProfile.activerallyid}/${this.files[index].name}`
          )
          .put(this.files[index])
      }
      this.busySaving = false
      this.$emit('input', false)
    },
    remove(file) {
      const arr = this.files
      arr.splice(arr.indexOf(file), 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.dropzone {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: background-color 0.2s;
}
div.input-container {
  min-width: 50%;
}
.v-input {
  ::v-deep div.v-input__control {
    div.v-input__slot {
      margin-top: 4px;
      margin-bottom: 0 !important;
    }
    div.v-messages {
      display: none;
    }
  }
}
input.filebtn {
  display: none;
}
</style>
