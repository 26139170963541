import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"blue","dark":"","flat":""}},[_c(VToolbarTitle,{staticClass:"ml-5"},[_vm._v("Address search")]),_c(VSpacer)],1),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,[_c('GmapAutocomplete',{staticClass:"form-control blue lighten-5 pa-2 elevation-2",staticStyle:{"width":"100%"},attrs:{"value":_vm.googleAutoCompleteLocation,"select-first-on-enter":true,"placeholder":"Enter address or landmark...","options":{
                geocode: true,
                fields: ['geometry']
              }},on:{"place_changed":_vm.autoCompleteAddressMarker}}),_c('small',{staticClass:"form-text text-muted"},[_vm._v("Enter address tor drag map marker to determine latitude and longitude")])],1)],1),(_vm.error)?_c(VRow,[_c(VCol,[_c(VAlert,{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")])],1)],1):_vm._e(),_c(VRow,[_c(VCol,[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"250px"},attrs:{"options":_vm.defaultMapOptions,"center":_vm.googleMarker,"zoom":10},on:{"click":function($event){return _vm.geolocateUpdateMarker($event.latLng)}}},[_c('GmapMarker',{key:"1",attrs:{"position":_vm.googleMarker,"clickable":true,"draggable":true},on:{"dragend":function($event){return _vm.geolocateUpdateMarker($event.latLng)}}})],1)],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"ml-3 mb-5 mr-2",attrs:{"color":"grey","text":""},on:{"click":function($event){return _vm.cancelDialog()}}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"ml-3 mb-5 mr-7 white--text",attrs:{"color":"green","elevation":"=0","disabled":!_vm.locationHasBeenPicked},on:{"click":function($event){return _vm.saveLocation()}}},[_vm._v("Use this location")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }