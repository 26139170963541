import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.value,"max-width":"700px"}},[_c(VCard,[_c(VForm,{ref:"formNonRidingBonus",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VToolbar,{attrs:{"color":"blue","dark":"","flat":""}},[_c(VToolbarTitle,[_vm._v("Add "+_vm._s(_vm.updatedBonus.symbol)+" bonus")]),_c(VSpacer)],1),_c(VCardText,{staticClass:"mt-3"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"label":"Bonus ID","dense":"","rules":[
                _vm.validateRequired(_vm.updatedBonus.name),
                _vm.validateHTML(_vm.updatedBonus.name),
                _vm.validateLength(_vm.updatedBonus.name, 250),
                _vm.validateCharacters(_vm.updatedBonus.name)
              ]},on:{"change":_vm.updateForm},model:{value:(_vm.updatedBonus.name),callback:function ($$v) {_vm.$set(_vm.updatedBonus, "name", $$v)},expression:"updatedBonus.name"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"label":"Points","dense":"","type":"number","rules":[
                _vm.validateRequired(_vm.updatedBonus.points),
                _vm.validateWholeNumber(_vm.updatedBonus.points),
                _vm.validateMinimum(_vm.updatedBonus.points, -100000),
                _vm.validateMaximum(_vm.updatedBonus.points, 100000)
              ]},on:{"change":_vm.updateForm},model:{value:(_vm.updatedBonus.points),callback:function ($$v) {_vm.$set(_vm.updatedBonus, "points", $$v)},expression:"updatedBonus.points"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VSelect,{attrs:{"items":_vm.selectTimezones,"item-text":"timezone","item-value":"timezoneid","label":"Timezone","dense":"","rules":[_vm.validateRequired(_vm.updatedBonus.timezoneid)]},on:{"change":_vm.updateForm},model:{value:(_vm.updatedBonus.timezoneid),callback:function ($$v) {_vm.$set(_vm.updatedBonus, "timezoneid", $$v)},expression:"updatedBonus.timezoneid"}})],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Comment","dense":"","clearable":"","rules":[
                _vm.validateHTML(_vm.updatedBonus.comment),
                _vm.validateLength(_vm.updatedBonus.comment, 250),
                _vm.validateCharacters(_vm.updatedBonus.comment)
              ]},on:{"change":_vm.updateForm},model:{value:(_vm.updatedBonus.comment),callback:function ($$v) {_vm.$set(_vm.updatedBonus, "comment", $$v)},expression:"updatedBonus.comment"}})],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"3"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":32,"transition":"scale-transition","offset-y":"","min-width":"100px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"label":"Start date","prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.windowStartDate),callback:function ($$v) {_vm.windowStartDate=$$v},expression:"windowStartDate"}},on))]}}]),model:{value:(_vm.menuBonusStartDate),callback:function ($$v) {_vm.menuBonusStartDate=$$v},expression:"menuBonusStartDate"}},[_c(VDatePicker,{attrs:{"min":_vm.windowStartDate,"max":_vm.windowEndDate,"picker-date":_vm.windowStartDate,"show-current":""},on:{"input":function($event){_vm.menuBonusStartDate = false},"change":_vm.updateForm},model:{value:(_vm.windowStartDate),callback:function ($$v) {_vm.windowStartDate=$$v},expression:"windowStartDate"}})],1)],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VMenu,{ref:"menuBonusStartTime",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.windowStartTime,"rules":[_vm.validateRequired(_vm.updatedBonus.points)],"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.windowStartTime=$event},"update:return-value":function($event){_vm.windowStartTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"label":"Start time","rules":[_vm.validateRequired(_vm.windowStartTime)],"prepend-icon":"mdi-clock-outline","readonly":"","dense":""},model:{value:(_vm.windowStartTime),callback:function ($$v) {_vm.windowStartTime=$$v},expression:"windowStartTime"}},on))]}}]),model:{value:(_vm.menuBonusStartTime),callback:function ($$v) {_vm.menuBonusStartTime=$$v},expression:"menuBonusStartTime"}},[(_vm.menuBonusStartTime)?_c(VTimePicker,{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menuBonusStartTime.save(_vm.windowStartTime)},"change":_vm.updateForm},model:{value:(_vm.windowStartTime),callback:function ($$v) {_vm.windowStartTime=$$v},expression:"windowStartTime"}}):_vm._e()],1)],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":32,"transition":"scale-transition","offset-y":"","min-width":"100px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"label":"End date","prepend-icon":"mdi-calendar","readonly":"","dense":""},on:{"change":_vm.updateForm},model:{value:(_vm.windowEndDate),callback:function ($$v) {_vm.windowEndDate=$$v},expression:"windowEndDate"}},on))]}}]),model:{value:(_vm.menuBonusEndDate),callback:function ($$v) {_vm.menuBonusEndDate=$$v},expression:"menuBonusEndDate"}},[_c(VDatePicker,{attrs:{"min":_vm.windowStartDate,"max":_vm.windowEndDate,"picker-date":_vm.windowStartDate,"show-current":""},on:{"input":function($event){_vm.menuBonusEndDate = false}},model:{value:(_vm.windowEndDate),callback:function ($$v) {_vm.windowEndDate=$$v},expression:"windowEndDate"}})],1)],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VMenu,{ref:"menuBonusEndTime",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.windowEndTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.windowEndTime=$event},"update:return-value":function($event){_vm.windowEndTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"label":"End time","rules":[_vm.validateRequired(_vm.windowEndTime)],"prepend-icon":"mdi-clock-outline","readonly":"","dense":""},on:{"change":_vm.updateForm},model:{value:(_vm.windowEndTime),callback:function ($$v) {_vm.windowEndTime=$$v},expression:"windowEndTime"}},on))]}}]),model:{value:(_vm.menuBonusEndTime),callback:function ($$v) {_vm.menuBonusEndTime=$$v},expression:"menuBonusEndTime"}},[(_vm.menuBonusEndTime)?_c(VTimePicker,{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menuBonusEndTime.save(_vm.windowEndTime)}},model:{value:(_vm.windowEndTime),callback:function ($$v) {_vm.windowEndTime=$$v},expression:"windowEndTime"}}):_vm._e()],1)],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"Longname","dense":"","readonly":""},model:{value:(_vm.updatedBonus.longname),callback:function ($$v) {_vm.$set(_vm.updatedBonus, "longname", $$v)},expression:"updatedBonus.longname"}})],1),_c(VCol,{attrs:{"cols":"5"}},[_c(VTextField,{attrs:{"label":"Value","readonly":"","dense":""},model:{value:(_vm.updatedBonus.value),callback:function ($$v) {_vm.$set(_vm.updatedBonus, "value", $$v)},expression:"updatedBonus.value"}})],1),_c(VCol,{attrs:{"cols":"1"}},[_c(VIcon,[_vm._v(_vm._s(_vm.updatedBonus.getMaterialicon()))])],1)],1),(_vm.updatedBonus.symbol === 'rest')?_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"Minimum rest stop duration","dense":"","rules":[
                _vm.validateRequired(_vm.updatedBonus.restminduration),
                _vm.validateMinimum(_vm.updatedBonus.restminduration, 15),
                _vm.validateMaximum(_vm.updatedBonus.restminduration, 1440)
              ]},model:{value:(_vm.updatedBonus.restminduration),callback:function ($$v) {_vm.$set(_vm.updatedBonus, "restminduration", $$v)},expression:"updatedBonus.restminduration"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"Maximum rest stop duration","dense":"","rules":[
                _vm.validateRequired(_vm.updatedBonus.restmaxduration),
                _vm.validateMinimum(
                  _vm.updatedBonus.restmaxduration,
                  _vm.updatedBonus.restminduration
                ),
                _vm.validateMaximum(_vm.updatedBonus.restmaxduration, 1440)
              ]},model:{value:(_vm.updatedBonus.restmaxduration),callback:function ($$v) {_vm.$set(_vm.updatedBonus, "restmaxduration", $$v)},expression:"updatedBonus.restmaxduration"}})],1)],1):_vm._e(),_c(VRow,{staticClass:"my-4"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"grey","dark":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"ml-7 mr-3 white--text",attrs:{"color":"green","disabled":!_vm.isValid,"loading":_vm.busySaveNonRidingBonusButton},on:{"click":function($event){return _vm.saveNonRidingBonus()}}},[_vm._v("Save bonus")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }