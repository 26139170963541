<template>
  <v-dialog v-model="value" persistent max-width="1000px">
    <v-card elevation="2">
      <v-form ref="formLocation" v-model="formNewRallyValidation">
        <v-toolbar flat color="blue" dark>
          <v-toolbar-title>Add new rally leg</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense class="mx-2">
            <v-col cols="12">
              <v-radio-group
                v-model="newRally.legid"
                row
                dense
                mandatory
                prepend-icon="mdi-counter"
              >
                <v-radio label="Leg 1" value="1" class="mr-10"></v-radio>
                <v-radio label="Leg 2" value="2" class="mr-10"></v-radio>
                <v-radio label="Leg 3" value="3" class="mr-10"></v-radio>
                <v-radio label="Leg 4" value="4" class="mr-10"></v-radio>
                <v-radio label="Leg 5" value="5" class="mr-10"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row dense class="mx-2">
            <v-col cols="12">
              <v-text-field
                v-model="newRally.rallyname"
                label="Enter the name of the rally"
                prepend-icon="mdi-highway"
                dense
                :rules="[
                  validateRequired(newRally.rallyname),
                  validateHTML(newRally.rallyname),
                  validateLength(newRally.rallyname, 250),
                  validateCharacters(newRally.rallyname)
                ]"
              ></v-text-field> </v-col
          ></v-row>
          <v-row dense class="mx-2">
            <v-col cols="12">
              <v-text-field
                v-model="newRally.description"
                prepend-icon="mdi-form-textbox"
                dense
                label="Description"
                type="text"
                :rules="[
                  validateHTML(newRally.description),
                  validateLength(newRally.description, 250),
                  validateCharacters(newRally.description)
                ]"
              ></v-text-field> </v-col
          ></v-row>
          <v-row dense class="mx-2">
            <v-col cols="12">
              <v-text-field
                v-model="newRally.rallyurl"
                prepend-icon="mdi-file-link-outline"
                dense
                clearable
                label="Rally website URL"
                type="text"
                :rules="[
                  validateURLprotocol(newRally.rallyurl),
                  validateIsURL(newRally.rallyurl),
                  validateHTML(newRally.rallyurl),
                  validateLength(newRally.rallyurl, 150),
                  validateCharacters(newRally.rallyurl)
                ]"
              ></v-text-field></v-col
          ></v-row>
          <v-row dense class="mx-2">
            <v-col cols="2">
              <v-menu
                v-model="menuStartDate"
                :close-on-content-click="false"
                :nudge-right="32"
                transition="scale-transition"
                offset-y
                min-width="100px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="newRally.start.date"
                    label="Start date"
                    prepend-icon="mdi-clock-start"
                    readonly
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="newRally.start.date"
                  show-current
                  @input="menuStartDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="1">
              <v-menu
                ref="menuStartTime"
                v-model="menuStartTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="newRally.start.time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="newRally.start.time"
                    label="Start time"
                    :rules="[validateRequired(newRally.start.time)]"
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuStartTime"
                  v-model="newRally.start.time"
                  full-width
                  @click:minute="$refs.menuStartTime.save(newRally.start.time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="startBonus.timezoneShort"
                dense
                readonly
                label="Start timezone"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3" class="ml-5">
              <v-text-field
                v-model="newRally.start.latitude"
                label="Start latitude"
                type="number"
                dense
                prepend-icon="mdi-map-marker"
                :rules="[
                  validateRequired(newRally.start.latitude),
                  validateMinimum(newRally.start.latitude, -90),
                  validateMaximum(newRally.start.latitude, 90)
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="newRally.start.longitude"
                label="Start longitude"
                type="number"
                dense
                append-icon="mdi-map-search"
                :rules="[
                  validateRequired(newRally.start.longitude),
                  validateMinimum(newRally.start.longitude, -180),
                  validateMaximum(newRally.start.longitude, 180)
                ]"
                @click:append="
                  rallyLocation = 'start'
                  showAddressSearchDialog = true
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="mx-2">
            <v-col cols="2">
              <v-menu
                v-model="menuEndDate"
                :close-on-content-click="false"
                :nudge-right="32"
                transition="scale-transition"
                offset-y
                min-width="100px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="newRally.end.date"
                    label="End date"
                    prepend-icon="mdi-clock-end"
                    readonly
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="newRally.end.date"
                  show-current
                  :min="newRally.start.date"
                  @input="menuEndDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="1">
              <v-menu
                ref="menuEndTime"
                v-model="menuEndTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="newRally.end.time"
                transition="scale-transition"
                :rules="[validateRequired(newBonus.points)]"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="newRally.end.time"
                    label="End time"
                    :rules="[validateRequired(newRally.end.time)]"
                    readonly
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuEndTime"
                  v-model="newRally.end.time"
                  full-width
                  @click:minute="$refs.menuEndTime.save(newRally.end.time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="endBonus.timezoneShort"
                dense
                readonly
                label="End timezone"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3" class="ml-5">
              <v-text-field
                v-model="newRally.end.latitude"
                label="End latitude"
                type="number"
                dense
                prepend-icon="mdi-map-marker"
                :rules="[
                  validateRequired(newRally.end.latitude),
                  validateMinimum(newRally.end.latitude, -90),
                  validateMaximum(newRally.end.latitude, 90)
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="newRally.end.longitude"
                label="End longitude"
                type="number"
                append-icon="mdi-map-search"
                dense
                :rules="[
                  validateRequired(newRally.end.longitude),
                  validateMinimum(newRally.end.longitude, -180),
                  validateMaximum(newRally.end.longitude, 180)
                ]"
                @click:append="
                  rallyLocation = 'end'
                  showAddressSearchDialog = true
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="mx-2">
            <v-col cols="4">
              <v-slider
                v-model="newRally.penaltywindow"
                min="0"
                max="240"
                step="15"
                thumb-label
                thumb-size="25"
                dense
                label="Penalty window"
                prepend-icon="mdi-clock-alert-outline"
              >
              </v-slider>
            </v-col>
            <v-col cols="2"
              ><div class="mt-1 grey--text">
                {{ newRally.penaltywindow }} minutes
              </div></v-col
            >
            <v-col cols="6">
              <v-text-field
                v-model="newRally.penaltypoints"
                dense
                type="text"
                label="Penalty points per minute"
                prepend-icon="mdi-elevation-decline"
                :rules="[
                  validateRequired(newRally.penaltypoints),
                  validateMinimum(newRally.penaltypoints, 0),
                  validateMaximum(newRally.penaltypoints, 10000)
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-toolbar
          v-if="formNewRallyValidation"
          dense
          flat
          color="blue lighten-3"
          dark
          class="mt-6"
        >
          <v-toolbar-title>Non-mappable bonuses</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="
              newRally.end.longitude &&
              newRally.end.latitude &&
              newRally.start.longitude &&
              newRally.start.latitude
            "
            dark
            small
            color="blue"
            class="ml-2"
          >
            <v-icon @click="addBonus('rest')">mdi-sleep</v-icon>
          </v-btn>
          <v-btn
            v-if="formNewRallyValidation"
            dark
            small
            color="blue"
            class="ml-2"
          >
            <v-icon @click="addBonus('call-in')">mdi-cellphone</v-icon>
          </v-btn>
          <v-btn
            v-if="formNewRallyValidation"
            dark
            small
            color="blue"
            class="ml-2"
          >
            <v-icon @click="addBonus('tracking')">mdi-radar</v-icon>
          </v-btn>
          <v-btn
            v-if="formNewRallyValidation"
            dark
            small
            color="blue"
            class="ml-2"
          >
            <v-icon @click="addBonus('other')">mdi-expand-all-outline</v-icon>
          </v-btn>
          <div v-if="!formNewRallyValidation">
            Non-mappable bonuses require start and end location to be specified
          </div>
        </v-toolbar>
        <v-card-text v-if="formNewRallyValidation">
          <v-row dense class="ma-2"
            ><v-col cols="12">
              <v-data-table
                dense
                disable-pagination
                hide-default-footer
                :headers="tableHeaders"
                :items="BonusLocationsByCategoryGetter('N')"
                item-key="name"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editBonus(item)"
                    >mdi-pencil</v-icon
                  >
                  <v-icon small @click="askDeleteBonus(item)"
                    >mdi-delete</v-icon
                  >
                </template>
                <template v-slot:item.timezoneid="{ item }">
                  {{
                    `${DateTime.now()
                      .setZone(item.timezoneid)
                      .toFormat('ZZZZ')} (${item.timezoneid})`
                  }}
                </template>
              </v-data-table>
            </v-col></v-row
          >
        </v-card-text>
        <v-card-actions>
          <div class="caption ml-12 mb-5 grey--text">
            <v-icon small color="grey">mdi-identifier</v-icon>
            {{ newRally.rallyid }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="grey"
            class="mb-5"
            elevation="0"
            @click="cancelRallyDialog()"
            >Cancel</v-btn
          >
          <v-btn
            color="green"
            class="mr-5 mb-5 white--text"
            elevation="0"
            :disabled="!formNewRallyValidation"
            :loading="loadingSaveRallyButton"
            @click="saveRally"
            >Save rally leg</v-btn
          ></v-card-actions
        >
      </v-form>
    </v-card>
    <dialogAddNonRidingBonus
      v-model="showAddBonusDialog"
      :newbonus="newBonus"
      :newrally="newRally"
    />
    <dialogDelete
      v-model="showDialogDelete"
      :objectid="objectidToBeDeleted"
      @delete="dialogDeleteAction"
    />
    <dialogAddressSearch
      v-model="showAddressSearchDialog"
      :editrally="editrally"
      :rallylocation="rallyLocation"
      @modified="updateStartStopLatLng"
    />
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import dialogAddressSearch from '@/components/dialogs/dialogAddressSearch'
import dialogDelete from '@/components/dialogs/dialogDelete'
import dialogAddNonRidingBonus from '@/components/dialogs/dialogAddNonRidingBonus'
import * as inputValidationRules from '@/components/inputValidationRules'
import { RalliesPrototype } from '@/components/prototypes/rallies'
import { BonusLocationsPrototype } from '@/components/prototypes/bonuslocations'
import * as Luxon from 'luxon'

export default {
  name: 'ShowAddRally',
  components: { dialogAddressSearch, dialogDelete, dialogAddNonRidingBonus },
  props: {
    value: { required: true, type: Boolean },
    editrally: { type: Object, value: null, default: null }
  },
  data() {
    const data = {
      objectidToBeDeleted: '',
      startBonus: new BonusLocationsPrototype(),
      endBonus: new BonusLocationsPrototype(),
      showAddressSearchDialog: false,
      showAddBonusDialog: false,
      loadingSaveRallyButton: false,
      newRally: {},
      newBonus: {},
      rallyLocation: 'start',
      tableHeaders: [
        { text: 'Bonus ID', sortable: true, value: 'name' },
        { text: 'Points', sortable: false, value: 'points' },
        { text: 'Value', sortable: false, value: 'value' },
        { text: 'Timezone', sortable: false, value: 'timezoneid' },
        { text: 'Bonus window', sortable: false, value: 'timerestrictions' },
        { text: 'Actions', sortable: false, value: 'actions' }
      ],
      formNewRallyValidation: false,
      menuStartDate: false,
      menuEndDate: false,
      menuStartTime: false,
      menuEndTime: false,
      showDialogDelete: false
    }
    return { ...data, ...inputValidationRules, ...Luxon }
  },
  computed: {
    ...mapState({
      UserProfile: (state) => state.moduleUser.UserProfile,
      BonusLocations: (state) => state.moduleBonusLocations.BonusLocations,
      Rallies: (state) => state.moduleRallies.Rallies
    }),
    ...mapGetters('moduleBonusLocations', [
      'BonusLocationsByCategoryGetter',
      'BonusLocationsByNameGetter'
    ])
  },
  watch: {
    value: function () {
      // dialog has been called and we're creating a new rally
      if (this.value === true && this.editrally.rallyid === null) {
        this.BonusLocationsClearAction()
        this.newRally = new RalliesPrototype()
        this.newRally.initializeRally()
        this.newRally.start.date = new Date().toISOString().substr(0, 10)
        this.newRally.start.time = '00:00'
        this.newRally.end.date = new Date().toISOString().substr(0, 10)
        this.newRally.end.time = '23:59'
      } else {
        // dialog has been called and we're editing an existing rally
        this.newRally = { ...this.editrally }
        if (this.newRally.legid)
          this.newRally.legid = this.newRally.legid.toString()
        this.newRally.__proto__ = RalliesPrototype.prototype
        this.newRally.start.date = this.DateTime.fromISO(
          this.newRally.start.datetime,
          { setZone: true }
        ).toFormat('yyyy-LL-dd')
        this.newRally.start.time = this.DateTime.fromISO(
          this.newRally.start.datetime,
          { setZone: true }
        ).toFormat('HH:mm')
        this.newRally.end.date = this.DateTime.fromISO(
          this.newRally.end.datetime,
          { setZone: true }
        ).toFormat('yyyy-LL-dd')
        this.newRally.end.time = this.DateTime.fromISO(
          this.newRally.end.datetime,
          { setZone: true }
        ).toFormat('HH:mm')
      }
      this.loadingSaveRallyButton = false
    }
  },
  created: function () {
    this.newRally = new RalliesPrototype()
    this.newBonus = new BonusLocationsPrototype()
  },
  methods: {
    ...mapActions('moduleBonusLocations', [
      'BonusLocationsDeleteAction',
      'BonusLocationsCreateUpdateAction',
      'BonusLocationsClearAction',
      'BonusLocationsReadAllAction'
    ]),
    ...mapActions('moduleRouteService', ['RouteServiceClearAction']),
    ...mapActions('moduleRallies', ['RalliesCreateUpdateAction']),
    ...mapActions('moduleUser', ['UserProfileSetActiveRallyAction']),
    async updateStartStopLatLng(_updatedRally) {
      if (_updatedRally.start.latitude) {
        this.newRally.start.latitude = Number(_updatedRally.start.latitude)
        this.newRally.start.longitude = Number(_updatedRally.start.longitude)
      }
      if (_updatedRally.end.latitude) {
        this.newRally.end.latitude = Number(_updatedRally.end.latitude)
        this.newRally.end.longitude = Number(_updatedRally.end.longitude)
      }
    },
    dialogDeleteAction(_event) {
      if (_event === 'bonus') {
        const bonusToBeDeleted = this.BonusLocations.find(
          (bonus) => bonus.bonusid === this.objectidToBeDeleted
        )
        this.BonusLocationsDeleteAction({
          bonus: bonusToBeDeleted,
          rallyid: this.newRally.rallyid
        })
      }
      this.objectidToBeDeleted = ''
    },
    askDeleteBonus(_bonusLocation) {
      this.objectidToBeDeleted = _bonusLocation.bonusid
      this.showDialogDelete = true
    },
    async editBonus(_bonusLocation) {
      this.newBonus = { ..._bonusLocation }
      this.newBonus.__proto__ = BonusLocationsPrototype.prototype
      this.showAddBonusDialog = true
    },
    async addBonus(_symbol) {
      this.newBonus = new BonusLocationsPrototype()
      this.newBonus.symbol = _symbol
      this.showAddBonusDialog = true
    },
    cancelRallyDialog() {
      if (this.UserProfile.activerallyid) {
        // we were editing an existing rally so now we need to read back the bonus locations before returning
        this.BonusLocationsReadAllAction(this.UserProfile.activerallyid)
      }
      this.$refs.formLocation.reset()
      this.$emit('input', false)
    },
    async saveRally() {
      let saverally = { ...this.newRally }
      this.loadingSaveRallyButton = true
      saverally.rallyurl = this.sanitizeURL(this.newRally.rallyurl)
      saverally.garmincategory = String(
        `${this.newRally.rallyname} - Leg ${this.newRally.legid}`
      )
      saverally.legid = Number(this.newRally.legid)
      saverally.onclockplanningtime = Number(this.newRally.onclockplanningtime)
      saverally.penaltywindow = Number(this.newRally.penaltywindow)
      saverally.penaltypoints = Number(this.newRally.penaltypoints)

      // make sure lat/long are all set for rally and START and END bonus locations
      this.startBonus.latitude = this.newRally.start.latitude
      this.startBonus.longitude = this.newRally.start.longitude
      this.endBonus.latitude = this.newRally.end.latitude
      this.endBonus.longitude = this.newRally.end.longitude

      // initialize the START and END bonus locations which includes determining their timezones
      await this.startBonus.setStartcheckpoint()
      await this.endBonus.setEndcheckpoint()

      // make sure timezone information is all set for rally and START and END bonus locations
      saverally.start.timezoneid = this.startBonus.timezoneid
      saverally.start.timezone = this.startBonus.timezoneShort
      saverally.end.timezoneid = this.endBonus.timezoneid
      saverally.end.timezone = this.endBonus.timezoneShort

      // now that we know the timezone, create an ISO datetime object from the input
      saverally.start.datetime = this.constructDateTime(
        this.newRally.start.date,
        this.newRally.start.time,
        this.newRally.start.timezoneid
      )
      saverally.end.datetime = this.constructDateTime(
        this.newRally.end.date,
        this.newRally.end.time,
        this.newRally.end.timezoneid
      )
      // calculate sunset and sunrise and make sure START and END as well as rally have this information
      this.startBonus.setSunrise(this.newRally.start.datetime)
      this.startBonus.setSunset(this.newRally.start.datetime)
      this.endBonus.setSunrise(this.newRally.end.datetime)
      this.endBonus.setSunset(this.newRally.end.datetime)
      saverally.start.sunrise = this.startBonus.sunrise
      saverally.start.sunset = this.startBonus.sunset
      saverally.end.sunrise = this.endBonus.sunrise
      saverally.end.sunset = this.endBonus.sunset

      // if we're updating existing START and END locations, make sure we reuse the bonusid
      if (this.BonusLocationsByNameGetter('START') != null)
        this.startBonus.bonusid =
          this.BonusLocationsByNameGetter('START').bonusid
      if (this.BonusLocationsByNameGetter('END') != null)
        this.endBonus.bonusid = this.BonusLocationsByNameGetter('END').bonusid

      // remove the fields from the newRally object that were used to fill the form
      delete saverally.start.time
      delete saverally.start.date
      delete saverally.end.time
      delete saverally.end.date

      saverally.recalculateroute = true
      await this.RalliesCreateUpdateAction(saverally)
      this.UserProfileSetActiveRallyAction(saverally.rallyid)
      this.BonusLocationsCreateUpdateAction(this.startBonus)
      this.BonusLocationsCreateUpdateAction(this.endBonus)
      this.RouteServiceClearAction()
      this.loadingSaveRallyButton = false
      this.$emit('input', false)
    },
    constructDateTime(_date, _time, _timezoneid) {
      return this.DateTime.fromFormat(
        `${_date} ${_time} ${_timezoneid}`,
        'yyyy-LL-dd HH:mm z',
        { setZone: true }
      )
    }
  }
}
</script>

<style></style>
