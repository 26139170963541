import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"700px"},on:{"click:outside":function($event){return _vm.buttonClicked('cancel')}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,{attrs:{"elevation":"2"}},[_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,{staticClass:"red--text"},[_vm._v(" Delete "+_vm._s(_vm.objectToBeDeleted.type)+"? ")])],1),_c(VCardText,[_c(VAlert,{staticClass:"ml-n2",attrs:{"text":"","type":"error"}},[_vm._v(" This will permanently delete all the data associated with this "+_vm._s(_vm.objectToBeDeleted.type)+". ")]),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"2"}},[_vm._v("ID:")]),_c(VCol,{staticClass:"font-weight-bold",attrs:{"cols":"10"}},[_vm._v(_vm._s(_vm.objectid))])],1),(_vm.objectToBeDeleted.name != _vm.objectid)?_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"2"}},[_vm._v("Name:")]),_c(VCol,{staticClass:"font-weight-bold",attrs:{"cols":"10"}},[_vm._v(_vm._s(_vm.objectToBeDeleted.name))])],1):_vm._e(),(_vm.objectToBeDeleted.complex)?_c(VRow,{staticClass:"mt-10",attrs:{"dense":""}},[_c(VCol,[_c('div',{staticClass:"body-1"},[_vm._v(" Confirm you want to delete this "+_vm._s(_vm.objectToBeDeleted.type)+" by typing its ID. ")])])],1):_vm._e(),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"8"}},[(_vm.objectToBeDeleted.complex)?_c(VTextField,{attrs:{"outlined":"","dense":"","clearable":"","rules":[
              _vm.validateRequired(_vm.deleteId),
              _vm.validateHTML(_vm.deleteId),
              _vm.validateLength(_vm.deleteId, 60),
              _vm.validateCharacters(_vm.deleteId),
              _vm.validateIdentical(_vm.deleteId, _vm.objectid)
            ]},model:{value:(_vm.deleteId),callback:function ($$v) {_vm.deleteId=$$v},expression:"deleteId"}}):_vm._e()],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VBtn,{staticClass:"ml-n3",attrs:{"plain":""},on:{"click":function($event){return _vm.buttonClicked('cancel')}}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"white--text",attrs:{"color":"red darken-1","elevation":"0","disabled":!_vm.objectToBeDeleted.readytodelete},on:{"click":function($event){return _vm.buttonClicked('submit')}}},[_vm._v(" Delete")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }