<template>
  <v-dialog
    v-model="value"
    max-width="700px"
    @click:outside="buttonClicked('cancel')"
  >
    <v-card elevation="2">
      <v-toolbar flat>
        <v-toolbar-title class="red--text">
          Delete {{ objectToBeDeleted.type }}?
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-alert class="ml-n2" text type="error">
          This will permanently delete all the data associated with this
          {{ objectToBeDeleted.type }}.
        </v-alert>
        <v-row dense>
          <v-col cols="2">ID:</v-col>
          <v-col cols="10" class="font-weight-bold">{{ objectid }}</v-col>
        </v-row>
        <v-row v-if="objectToBeDeleted.name != objectid" dense>
          <v-col cols="2">Name:</v-col>
          <v-col cols="10" class="font-weight-bold">{{
            objectToBeDeleted.name
          }}</v-col>
        </v-row>
        <v-row v-if="objectToBeDeleted.complex" dense class="mt-10"
          ><v-col
            ><div class="body-1">
              Confirm you want to delete this {{ objectToBeDeleted.type }} by
              typing its ID.
            </div></v-col
          ></v-row
        >
        <v-row dense
          ><v-col cols="8">
            <v-text-field
              v-if="objectToBeDeleted.complex"
              v-model="deleteId"
              outlined
              dense
              clearable
              :rules="[
                validateRequired(deleteId),
                validateHTML(deleteId),
                validateLength(deleteId, 60),
                validateCharacters(deleteId),
                validateIdentical(deleteId, objectid)
              ]"
            ></v-text-field></v-col
        ></v-row>
        <v-row dense>
          <v-btn plain class="ml-n3" @click="buttonClicked('cancel')"
            >Cancel</v-btn
          >
          <v-btn
            color="red darken-1"
            class="white--text"
            elevation="0"
            :disabled="!objectToBeDeleted.readytodelete"
            @click="buttonClicked('submit')"
          >
            Delete</v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import * as inputValidationRules from '@/components/inputValidationRules'
import { mapGetters } from 'vuex'
export default {
  name: 'DialogDelete',
  props: {
    value: { required: true, type: Boolean },
    objectid: { required: true, type: String }
  },
  data() {
    const data = {
      objectToBeDeleted: {},
      deleteId: null
    }
    return { ...data, ...inputValidationRules }
  },
  computed: {
    ...mapGetters('moduleRallies', ['RalliesGetRallyByIdGetter']),
    ...mapGetters('moduleRouteStops', ['RouteStopsByIdGetter']),
    ...mapGetters('moduleRoutes', ['RoutesGetRouteByIdGetter']),
    ...mapGetters('moduleBonusLocations', ['BonusLocationsByIdGetter'])
  },
  watch: {
    deleteId: function () {
      if (this.deleteId === this.objectid) {
        this.objectToBeDeleted.readytodelete = true
      } else {
        this.objectToBeDeleted.readytodelete = false
      }
    },
    value: function () {
      if (this.value === true) {
        this.deleteId = null
        this.objectToBeDeleted.complex = false
        this.objectToBeDeleted.readytodelete = true
        //figure out what this is: rally, routestop, bonus location, combo bonus or imported GPX
        if (this.objectid === 'importedgpxfile') {
          this.objectToBeDeleted.type = 'GPX file'
          this.objectToBeDeleted.name = 'Imported GPX file'
          this.objectToBeDeleted.complex = true
          this.objectToBeDeleted.readytodelete = false
          return
        }
        if (this.RalliesGetRallyByIdGetter(this.objectid).rallyname !== null) {
          this.objectToBeDeleted.type = 'rally'
          this.objectToBeDeleted.name = this.RalliesGetRallyByIdGetter(
            this.objectid
          ).rallyname
          this.objectToBeDeleted.complex = true
          this.objectToBeDeleted.readytodelete = false
          return
        }
        if (this.RouteStopsByIdGetter(this.objectid).name !== null) {
          this.objectToBeDeleted.type = 'routestop'
          this.objectToBeDeleted.name = this.RouteStopsByIdGetter(
            this.objectid
          ).name
          return
        }
        if (this.RoutesGetRouteByIdGetter(this.objectid).description !== null) {
          this.objectToBeDeleted.type = 'route'
          this.objectToBeDeleted.name = this.RoutesGetRouteByIdGetter(
            this.objectid
          ).description
          return
        }
        if (this.BonusLocationsByIdGetter(this.objectid).name !== null) {
          if (
            this.BonusLocationsByIdGetter(this.objectid).category.includes(
              'C'
            ) === true
          ) {
            this.objectToBeDeleted.type = 'combo'
          } else {
            this.objectToBeDeleted.type = 'bonus'
          }
          this.objectToBeDeleted.name = this.BonusLocationsByIdGetter(
            this.objectid
          ).name
        } else {
          // no other choices left but this being a file that needs to be deleted....
          this.objectToBeDeleted.type = 'file'
          this.objectToBeDeleted.name = this.objectid
        }
      }
    }
  },
  methods: {
    buttonClicked(_button) {
      if (_button === 'submit') {
        this.$emit('delete', this.objectToBeDeleted.type)
        this.deleteId = null
      } else {
        this.$emit('delete', 'cancelled')
      }
      this.$emit('input', false)
    }
  }
}
</script>

<style></style>
